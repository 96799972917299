export default class FPeriode {
  constructor(
    id,
    kode1,
    description,
    fdivisionBean,
    statusActive,
    avatarImage,

    daftarOpenFrom,
    daftarCloseTo,
    periodeFrom,
    periodeTo,
    tahun,
    notes,

    created,
    modified,
    modifiedBy

  ) {

    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.avatarImage = avatarImage;


    this.daftarOpenFrom = daftarOpenFrom;
    this.daftarCloseTo = daftarCloseTo;
    this.periodeFrom = periodeFrom;
    this.periodeTo = periodeTo;
    this.tahun = tahun;
    this.notes = notes;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}

<template>
  <v-container>
        <div class="grey lighten-5 pa-2">

          <v-row>
            <v-col
                cols="12"
                sm="7"
                md="7"
            >
              <v-text-field
                  v-model="itemModified.namaPrshFakturPajak"
                  label="Nama Perusaan pada Faktur Pajak"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="5"
                md="5"
            >
              <v-text-field
                  v-model="itemModified.namaPengusahaKenaPajak"
                  label="Nama Pengusaha Kena Pajak"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  v-model="itemModified.npwp"
                  label="Nomor NPWP"
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="itemModified.npwp"
            >
              <v-menu
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  max-width="290"
              >
                <template v-slot:activator="{ on, attrs } ">
                  <v-text-field
                      :value="computedDateFormattedDatefns(itemModified.tanggalPengukuhanPkp)"
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      label="Tanggal PKP"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="itemModified.tanggalPengukuhanPkp = null"
                  ></v-text-field>
                </template>

                <v-date-picker
                    v-model="itemModified.tanggalPengukuhanPkp"
                    @change="menuDate1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

          </v-row>

        </div>
  </v-container>
</template>

<script>

import FKurikulum from "../../../models/f-kurikulum";
import {format, parseISO} from "date-fns";

export default {
  props:{
    itemModified: new FKurikulum(),
  },
  data(){
    return{

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,

      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  methods: {
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

  }

}
</script>

<style scoped>

</style>
export default class FKurikulum {
  constructor(
    id,
    kode1,
    description,
    fdivisionBean,
    statusActive,
    avatarImage,

    kuotaMale,
    kuotaFemale,
    fkompBiayaBean,
    amountBiaya,
    notesBiaya,
    fperiodeBean,
    fmatPelBean,

    fperiodeBeanRes,

    created,
    modified,
    modifiedBy

  ) {

    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.avatarImage = avatarImage;

    this.kuotaMale = kuotaMale;
    this.kuotaFemale = kuotaFemale;
    this.fkompBiayaBean = fkompBiayaBean;
    this.amountBiaya = amountBiaya;
    this.notesBiaya = notesBiaya;
    this.fperiodeBean = fperiodeBean;
    this.fmatPelBean = fmatPelBean;

    this.fperiodeBeanRes = fperiodeBeanRes;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
